import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

// Action imports
import { Checkbox } from '@oup/shared-front-end';
import {
  setProductSelected,
  gotoReviewLicences,
  selectProduct
} from '../../../redux/reducers/assignLearningMaterial.reducer.js';
import { triggerSearch, setTerm, setPage, setFilter } from '../../../redux/reducers/data/search.reducer.js';

// Component imports
import PanelHeading from '../../../components/PanelHeading/PanelHeading.js';
import ScrollContainer from '../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../components/PanelNavigationLink/PanelNavigationLink.js';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon.js';
import Button, { buttonTypes } from '../../../components/Button/Button.js';
import PaginationButtons from '../../../components/PaginationButtons/PaginationButtons.js';
import { sanitizeUrl } from '../../../utils/url.js';

import { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail.js';
import ContentWithButton from '../../../components/ContentWithButton/ContentWithButton.js';
import MaterialRepresentation from '../../../components/MaterialRepresentation/MaterialRepresentation.js';
import cmsContent from '../../../utils/cmsContent.js';
import setTermAssignmentProduct from '../utils/setTermAssignmentProduct';

import PanelSearchControl from '../../../components/PanelSearchControl/PanelSearchControl';
import SubSectionSkeletonLoader from '../../../components/SkeletonLoader/SubSectionSkeletonLoader';
import { featureIsEnabled } from '../../../globals/envSettings';
import styles from './AssignLearningMaterial.scss';
import colors from '../../../globals/colors';
import Badge from '../../../components/Badge/Badge';
import PopoutNavFooter from '../../../components/PopoutNavFooter/PopoutNavFooter';

import { STATUS_TYPE } from '../../../globals/appConstants';
import { HubLayoutConstants } from '../../../globals/hubConstants';

let CMS = {};
export const licenceAvailabilityText = productData => {
  CMS = cmsContent.assignLearningMaterialPanel || {};
  if (productData?.availableCount > 0) {
    return featureIsEnabled('add-multiple-learning-material')
      ? `${productData?.availableCount} ${CMS.licenses_available_text}`
      : `${productData?.availableCount} ${CMS.count_licences_available_text}`;
  }
  if (productData?.assignedCount > 0 || productData?.redeemedCount > 0) {
    return CMS.no_licences_available_text;
  }
  return null;
};
const licenceAvailabilityStatusCustom = productData => {
  if (productData.availableCount > 0) {
    return STATUS_TYPE.SUCCESS;
  }
  if (productData.assignedCount > 0 || productData.redeemedCount > 0) {
    return STATUS_TYPE.ERROR;
  }
  return null;
};

const licenceAvailabilityStatus = (
  { activationCodes, redeemedCount, assignedCount, availableCount },
  selectedUsers = []
) => {
  let totalUsers = selectedUsers.length;
  (activationCodes || []).forEach(activationCode => {
    if (activationCode.assignedCount > 0 && selectedUsers.includes(activationCode.assignedUserid)) {
      totalUsers -= 1;
    }
  });
  if (availableCount > 0 && totalUsers <= availableCount) {
    return STATUS_TYPE.SUCCESS;
  }
  if (availableCount > 0 && totalUsers > availableCount) {
    return STATUS_TYPE.WARNING;
  }
  if (assignedCount > 0 || redeemedCount > 0) {
    return STATUS_TYPE.ERROR;
  }
  return null;
};

function AssignLearningMaterialSearch({
  closePopoutAction,
  contextName,
  loadingResults,
  searchFailed,
  resultIds,
  totalResults,
  products,
  page,
  setSearchTerm,
  setFilterAction,
  setPageAction,
  triggerSearchAction,
  selectProductAction,
  setProductSelectedAction,
  gotoReviewLicencesAction,
  selectedProductIds,
  selectedUsers,
  defaultFilterUsertype,
  defaultSelectedFilterUsertype
}) {
  CMS = cmsContent.assignLearningMaterialPanel || {};
  const { TARGET_USERTYPE } = HubLayoutConstants;
  const isMaxSelectionExceed = selectedProductIds.length >= 10;

  const [selectedFilterOptions, setSelectedFilterOptions] = useState(defaultSelectedFilterUsertype);
  const setFilterByUserType = selectedOptions => {
    if (defaultFilterUsertype.length === 1 && defaultFilterUsertype.includes(TARGET_USERTYPE.STUDENT)) return false;
    setSelectedFilterOptions(selectedOptions);
    setFilterAction('usertype', selectedOptions);
    return true;
  };

  const searchFilterOptionsByUserType = [
    {
      id: 0,
      text: CMS.students_text,
      value: TARGET_USERTYPE.STUDENT,
      checked: selectedFilterOptions.includes(TARGET_USERTYPE.STUDENT),
      isDisabled: false
    },
    {
      id: 1,
      text: CMS.teachers_text,
      value: TARGET_USERTYPE.TEACHER,
      checked: selectedFilterOptions.includes(TARGET_USERTYPE.TEACHER),
      isDisabled: defaultFilterUsertype.length === 1 && defaultFilterUsertype.includes(TARGET_USERTYPE.STUDENT)
    }
  ];

  const searchFilterOptions = [
    {
      text: CMS.show_all_material_label,
      value: 'ALL'
    },
    {
      text: CMS.show_library_material_label,
      value: 'LIBRARY'
    }
  ];

  return (
    // role="search": http://adrianroselli.com/2015/08/where-to-put-your-search-role.html
    <ScrollContainer
      // @ts-ignore
      role="search"
      headerContent={
        <div>
          <div className="text-right">
            <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePopoutAction} />
          </div>
          <PanelHeading
            title={CMS.assign_material_choose_title}
            subtitle={(
              (featureIsEnabled('add-multiple-learning-material')
                ? CMS.assign_material_for
                : CMS.assign_material_to_assignee_text) || ''
            ).replace('{assigneeName}', contextName)}
          />
          <PanelSearchControl
            setFilterByUserType={setFilterByUserType}
            filterOptions={searchFilterOptions}
            filterOptionsByUserType={searchFilterOptionsByUserType}
            placeholder={CMS.search_label_placeholder}
            setFilter={values => setFilterAction('showOnlyRedeemed', values[0] === 'LIBRARY')}
            setSearchTerm={value => setTermAssignmentProduct(setSearchTerm, value)}
            panelType={HubLayoutConstants.FILTER_PANEL_TYPES.MATERIALS}
            initialSelectedFilterOptions={['ALL']}
          />
        </div>
      }
      footerContent={
        featureIsEnabled('add-multiple-learning-material') && (
          <div>
            {selectedProductIds.length ? (
              <div id="selectedTotal" className={styles.selectedFooter}>
                <Badge
                  backgroundColor={
                    // @ts-ignore
                    colors.PRIMARY_BLUE
                  }
                  value={selectedProductIds.length}
                />
                <span>
                  &nbsp;{CMS.selected_text} {isMaxSelectionExceed && CMS.maximum_text}
                </span>
              </div>
            ) : null}

            <PopoutNavFooter
              nextButtonText={CMS.next_button_text}
              nextButtonDisabled={!(selectedProductIds && selectedProductIds.length > 0)}
              nextAction={gotoReviewLicencesAction}
              backButtonText={CMS.cancel_button_text}
              backAction={closePopoutAction}
            />
          </div>
        )
      }
    >
      <div>
        {loadingResults ? (
          <SubSectionSkeletonLoader
            largeRectWidth="60%"
            smallRectWidth="40%"
            speed={2}
            foregroundColor={colors.COLOR_GREY_DISABLED2}
            backgroundColor={colors.COLOR_WHITE}
          />
        ) : null}

        {searchFailed ? (
          <div style={{ textAlign: 'center' }}>
            <div style={{ width: '5rem', margin: '2rem auto 0' }}>
              <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
            </div>
            <p className="gin2">{CMS.error_search_results_text}</p>
            <Button text={CMS.retry_button_text} onClick={triggerSearchAction} id="retryBtn" />
          </div>
        ) : null}

        {!loadingResults && !searchFailed && (!resultIds || resultIds.length === 0) && (
          <div className="gin2">
            <p>{CMS.no_search_results_text}</p>
          </div>
        )}
        {!loadingResults && !searchFailed && resultIds && resultIds.length > 0 && (
          <ol id="assignmentResultList">
            {resultIds.map(productId => {
              const product = products[productId];
              const selectedStatus = (selectedProductIds || []).includes(productId);
              const teacherTagText = product.target_usertype === TARGET_USERTYPE.TEACHER ? CMS.teacher_material : '';
              return (
                <li
                  key={productId}
                  className={classnames(styles.assignLmProductList, 'pad-top1 pad-bot1 pad-left2 pad-right2', {
                    [styles.selected]: selectedStatus,
                    [styles.disabledClass]: isMaxSelectionExceed && !selectedStatus
                  })}
                >
                  {featureIsEnabled('add-multiple-learning-material') ? (
                    <Checkbox
                      id={`searchResults-checkbox-select-${productId}`}
                      label={product.title || ''}
                      hideLabel
                      value={selectedStatus}
                      onChange={() => {
                        setProductSelectedAction(productId, !selectedStatus);
                      }}
                      disabled={isMaxSelectionExceed && !selectedStatus}
                    >
                      <MaterialRepresentation
                        thumbnailSize={thumbnailSizes.TABLE}
                        imageSrc={sanitizeUrl(product.coverImage)}
                        name={product.title}
                        subtext={product.subtitle}
                        statusText={licenceAvailabilityText(product)}
                        statusColor={licenceAvailabilityStatus(product, selectedUsers)}
                        statusIndication
                        teacherTagText={teacherTagText}
                      />
                    </Checkbox>
                  ) : (
                    <ContentWithButton
                      id={`selectProduct${productId}`}
                      buttonText={product.title}
                      buttonGlyph={GLYPHS.ICON_RIGHT_THICK}
                      buttonOnClick={() => selectProductAction(productId)}
                      buttonType={buttonTypes.PRIMARY}
                      buttonIconOnly
                    >
                      <MaterialRepresentation
                        thumbnailSize={thumbnailSizes.TABLE}
                        imageSrc={sanitizeUrl(product.coverImage)}
                        name={product.title}
                        subtext={product.subtitle}
                        statusText={licenceAvailabilityText(product)}
                        statusColor={licenceAvailabilityStatusCustom(product)}
                        teacherTagText={teacherTagText}
                      />
                    </ContentWithButton>
                  )}
                </li>
              );
            })}
          </ol>
        )}

        {!loadingResults && totalResults > 10 ? (
          <div className="gin2">
            <PaginationButtons
              idPrefix="classStudentSearch"
              value={page}
              numberOfPages={Math.ceil(totalResults / 10)}
              onClick={setPageAction}
              aria={{ 'aria-controls': 'assignmentResultList' }}
            />
          </div>
        ) : null}
      </div>
    </ScrollContainer>
  );
}

AssignLearningMaterialSearch.propTypes = {
  closePopoutAction: PropTypes.func.isRequired,
  contextName: PropTypes.string.isRequired,
  // Search data
  loadingResults: PropTypes.bool.isRequired,
  searchFailed: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  resultIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  totalResults: PropTypes.number.isRequired,
  products: PropTypes.object.isRequired,
  // Actions
  setSearchTerm: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  setPageAction: PropTypes.func.isRequired,
  triggerSearchAction: PropTypes.func.isRequired,
  selectProductAction: PropTypes.func.isRequired,
  setProductSelectedAction: PropTypes.func.isRequired,
  gotoReviewLicencesAction: PropTypes.func.isRequired,
  selectedProductIds: PropTypes.array.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  defaultFilterUsertype: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  defaultSelectedFilterUsertype: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

export default connect(
  state => {
    const searchInstance = state.search.assignmentProducts || {};
    const { selectedProductIds = [] } = state.assignLearningMaterial;

    return {
      loadingResults: searchInstance.loading,
      searchFailed: searchInstance.error,
      page: searchInstance.page,
      resultIds: searchInstance.ids,
      totalResults: searchInstance.totalResults,
      products: state.products.data,
      selectedProductIds
    };
  },
  {
    setSearchTerm: term => setTerm('assignmentProducts', term),
    setPageAction: page => setPage('assignmentProducts', page),
    setFilterAction: (valueName, value) => setFilter('assignmentProducts', valueName, value),
    triggerSearchAction: triggerSearch.bind(null, 'assignmentProducts'),
    selectProductAction: selectProduct,
    setProductSelectedAction: setProductSelected,
    gotoReviewLicencesAction: gotoReviewLicences
  }
)(AssignLearningMaterialSearch);
