import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { RadioButtonGroup, CheckboxGroup } from '@oup/shared-front-end';
import styles from './HubFilterOptions.scss';
import Button, { buttonTypes } from '../Button/Button';
import { validGlyphs } from '../SVGIcon/SVGIcon';
import PopupOverlay from '../PopupOverlay/PopupOverlay';
import colors, { hexCodes } from '../../globals/colors';

function HubFilterOptions({
  buttonId,
  buttonText,
  buttonCustomClassName,
  buttonGlyph,
  a11yHeading,
  inputType,
  options,
  shallow,
  onChange,
  iconOnly,
  showLegend = true,
  overlayHangRight = true,
  overlayCustomClass,
  initialSelectedValues = []
}) {
  const [programmaticId] = Math.random()
    .toString(36)
    .substring(7);
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState(
    initialSelectedValues.length > 0 ? initialSelectedValues : options.filter(opt => opt.checked).map(opt => opt.value)
  );

  useEffect(() => {
    setSelectedValues(options.filter(opt => opt.checked).map(opt => opt.value));
  }, [options]);

  const togglePopup = () => setPopupOpen(prev => !prev);

  const menuId = `${buttonId}-menu`;
  const summaryOfSelections = options
    .filter(option => option.checked)
    .map(option => option.text)
    .join(', ');

  const isSingleOption = options.length < 2;

  return (
    <div className={classnames(styles.container, { [styles.containerShallow]: shallow })}>
      <Button
        id={buttonId}
        type={iconOnly ? buttonTypes.ROUNDED : buttonTypes.DROPDOWN}
        text={buttonText}
        glyph={buttonGlyph}
        glyphFill={hexCodes[colors.PRIMARY]}
        onClick={!isSingleOption ? togglePopup : null}
        aria={{
          haspopup: 'true',
          owns: menuId,
          controls: menuId,
          expanded: popupOpen,
          label: summaryOfSelections ? `${a11yHeading} ${summaryOfSelections}` : null
        }}
        iconOnly={iconOnly}
        customClassName={buttonCustomClassName}
        hidePostIcon={isSingleOption}
      />

      {!isSingleOption && (
        <PopupOverlay
          id={menuId}
          customClassName={classnames(styles.popupOverlay, overlayHangRight ? styles.hangRight : styles.hangLeft, {
            [overlayCustomClass]: overlayCustomClass
          })}
          visible={popupOpen}
          hangRight={overlayHangRight}
          onTogglePopup={togglePopup}
          returnFocusTo={buttonId}
          aria={{ labelledby: buttonId, expanded: popupOpen }}
          buttonElementId={buttonId}
          role="group"
        >
          {inputType === 'radio' ? (
            <RadioButtonGroup
              name={programmaticId}
              variant="transparent"
              legend={showLegend}
              classnames={{ container: styles.radioButtonGroupContainer }}
              required={false}
              checkedValue={selectedValues}
              radioGroup={options.map(option => ({
                id: `control-${option.id}-${programmaticId}`,
                name: option.name,
                value: option.value,
                disabled: option.isDisabled || false,
                checkedValue: selectedValues.includes(option.value),
                type: 'transparent',
                label: option.text,
                onChange: option.onChange
                  ? event => {
                      const newSelectedValues = event.target.checked
                        ? [...selectedValues, option.value]
                        : selectedValues.filter(val => JSON.stringify(val) !== JSON.stringify(option.value));
                      setSelectedValues(newSelectedValues);
                      option.onChange(option.value, event.target.checked);
                      if (onChange) onChange(newSelectedValues);
                    }
                  : undefined
              }))}
              onChange={event => {
                setSelectedValues([event.target.value]);
                if (onChange) onChange([event.target.value]);
              }}
            />
          ) : (
            <CheckboxGroup
              gap="gap_3"
              items={options.map(option => ({
                id: `control-${option.id}-${programmaticId}`,
                name: option.value,
                value: option.value,
                checked: selectedValues.includes(option.value),
                label: option.text,
                disabled: option.isDisabled,
                onChange: option.onChange
                  ? event => {
                      const newSelectedValues = event.target.checked
                        ? [...selectedValues, option.value]
                        : selectedValues.filter(val => JSON.stringify(val) !== JSON.stringify(option.value));
                      setSelectedValues(newSelectedValues);
                      option.onChange(option.value, event.target.checked);
                    }
                  : undefined
              }))}
              legend={a11yHeading}
              variant="transparent"
            />
          )}
        </PopupOverlay>
      )}
    </div>
  );
}

HubFilterOptions.propTypes = {
  buttonId: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonCustomClassName: PropTypes.string,
  buttonGlyph: PropTypes.oneOf(validGlyphs),
  a11yHeading: PropTypes.string.isRequired,
  inputType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  shallow: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      isDisabled: PropTypes.bool
    })
  ).isRequired,
  iconOnly: PropTypes.bool,
  showLegend: PropTypes.bool,
  overlayHangRight: PropTypes.bool,
  overlayCustomClass: PropTypes.string,
  initialSelectedValues: PropTypes.arrayOf(PropTypes.string)
};

export default HubFilterOptions;
