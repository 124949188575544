import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import compose from '../../utils/compose/compose.js';
import styles from './ContentPlayerSidebar.scss';
import { GlyphIcon } from '../IconEmblem/IconEmblem';
import SVGIcon from '../SVGIcon/SVGIcon';
import ActivityLevelMenu from './ActivityLevelMenu';
import {
  setCurrentContent,
  toggleContentsSubmenuModal,
  closeMobileMenuModal
} from '../../redux/actions/structuredContentPlayer';
import { invokeActionOnNodes } from '../../redux/reducers/cpt/treeHelperMethods';
import { onKeyDownHandler } from '../../services/cptHelperMethods';
import { TEACHER_VIEW_MODE } from '../../globals/cptConstants';
import changeRoleToProductVariant from '../StructuredContentPlayer/structuredContentPlayerUtils';
import { isOcpMode } from '../../utils/platform';

function formatData(currentOpenedLevels, hierarchicalContent) {
  if (currentOpenedLevels && currentOpenedLevels.length > 0) {
    invokeActionOnNodes({ ...hierarchicalContent, value: true }, node => {
      if (!node.isActivity) {
        if (node.id) {
          node.value = currentOpenedLevels.includes(node.id);
        }
      } else {
        node.isSelected = currentOpenedLevels.includes(node.id);
      }
    });
  }

  return { ...hierarchicalContent, value: true };
}

function UnitLessonLevelMenu({
  initialHierarchicalContent,
  cptContent,
  currentNodesIds,
  productVariant,
  lockedNodes,
  hideActivities,
  currentActivity,
  offlineUnits,
  setCurrentContentData,
  toggleModal,
  closeMobileMenuModalAction
}) {
  const initialIsOpen = formatData(currentNodesIds, initialHierarchicalContent);
  const [hierarchicalContent, setHierarchicalContent] = useState(initialIsOpen);
  const viewVersion = changeRoleToProductVariant(productVariant);
  const { userId: offlineUserId } = useParams();

  function handleToggle(node) {
    const newData = cloneDeep(hierarchicalContent);
    invokeActionOnNodes(newData, checkedNode => {
      if (checkedNode.id === node.id) {
        checkedNode.value = !checkedNode.value;
      }
    });
    setHierarchicalContent({ ...newData });
  }

  const handleActivitySelect = activityId => {
    if (currentActivity !== activityId) {
      setCurrentContentData(activityId);
    }
    toggleModal();
    closeMobileMenuModalAction();
  };

  const hasGreyTitle = isUnitDownloaded => isOcpMode() && !isUnitDownloaded;

  function renderNodesInMenu(node, isParentLocked = false) {
    function renderChild(child, isLockedParent, isUnitDownloaded) {
      const fragment = [];

      if (child.activities && !hideActivities) {
        const activitiesRender = (
          <ActivityLevelMenu
            key={`${child.id}`}
            activities={child.activities.map(activity => ({
              ...activity,
              isClickable: isLockedParent ? !isLockedParent : activity.isClickable
            }))}
            cptContent={cptContent}
          />
        );
        fragment.push(activitiesRender);
      }

      if (child.children) {
        const nestedChildren = Object.keys(child.children).map(key => child.children[key]);
        nestedChildren.forEach(nestedChild => {
          const isLocked =
            lockedNodes.includes(nestedChild.id) || lockedNodes.includes(nestedChild.parentId) || isLockedParent;
          const activityToStart = hideActivities && nestedChild.activities && nestedChild.activities[0];
          const nestedRender = (
            <div key={nestedChild.id} style={{ marginLeft: '16px' }}>
              <div
                className={`${styles.menuItem} ${nestedChild.value ? styles.menuItemExtended : ''}`}
                role="button"
                tabIndex={0}
                aria-expanded={child.value}
                onClick={() => (activityToStart ? handleActivitySelect(activityToStart.id) : handleToggle(nestedChild))}
                onKeyDown={onKeyDownHandler(() => handleToggle(nestedChild))}
              >
                <p className={classnames({ [styles.greyTitle]: hasGreyTitle(isUnitDownloaded) })}>
                  {nestedChild.title}
                </p>
                {!activityToStart && (
                  <div className={`${styles.caratIcon} ${nestedChild.value ? styles.caratIconReverse : ''}`}>
                    <SVGIcon className={styles.svgIcon} glyph={GlyphIcon.ICON_DOWN} />
                  </div>
                )}
              </div>
              {nestedChild.value && renderChild(nestedChild, isLocked, isUnitDownloaded)}
            </div>
          );
          fragment.push(nestedRender);
        });
      }

      return fragment;
    }

    if (node.value) {
      return Object.keys(node.children)
        .filter(key => key !== 'id' && key !== 'value' && key !== 'title' && key !== 'parentId')
        .map(key => {
          const child = node.children[key];
          const isLocked = lockedNodes.includes(child.id) || lockedNodes.includes(child.parentId) || isParentLocked;

          if (child.title) {
            const activityToStart = hideActivities && child.activities && child.activities[0];
            const isUnitDownloaded = offlineUnits.some(
              offlineUnit => offlineUnit.id === child.id && offlineUnit.userId === offlineUserId
            );

            if (isLocked && viewVersion !== TEACHER_VIEW_MODE) {
              return (
                <div key={`${child.id}`} style={{ marginLeft: '16px' }}>
                  <div
                    className={`${styles.menuItem} ${child.value ? styles.menuItemExtended : ''}`}
                    role="button"
                    tabIndex={0}
                    aria-expanded={child.value}
                    onClick={() => handleToggle(child)}
                    onKeyDown={onKeyDownHandler(() => handleToggle(child))}
                  >
                    <p className={classnames({ [styles.greyTitle]: hasGreyTitle(isUnitDownloaded) })}>{child.title}</p>
                    <div className={styles.svgIconLockContainer}>
                      <SVGIcon
                        className={child.value ? `${styles.svgIconLockWhite}` : styles.svgIconLockPrimary}
                        glyph={GlyphIcon.ICON_LOCK_24}
                      />
                      <div className={`${styles.caratIcon} ${child.value ? styles.caratIconReverse : ''}`}>
                        <SVGIcon className={styles.svgIcon} glyph={GlyphIcon.ICON_DOWN} />
                      </div>
                    </div>
                  </div>
                  {child.value && renderChild(child, isLocked, isUnitDownloaded)}
                </div>
              );
            }
            return (
              <div key={`${child.id}`} style={{ marginLeft: '16px' }}>
                <div
                  className={`${styles.menuItem} ${child.value ? styles.menuItemExtended : ''}`}
                  role="button"
                  aria-expanded={child.value}
                  tabIndex={0}
                  onClick={() => (activityToStart ? handleActivitySelect(activityToStart.id) : handleToggle(child))}
                  onKeyDown={onKeyDownHandler(() => handleToggle(child))}
                >
                  <p className={classnames({ [styles.greyTitle]: hasGreyTitle(isUnitDownloaded) })}>{child.title}</p>
                  {!activityToStart && (
                    <div className={`${styles.caratIcon} ${child.value ? styles.caratIconReverse : ''}`}>
                      <SVGIcon className={styles.svgIcon} glyph={GlyphIcon.ICON_DOWN} />
                    </div>
                  )}
                </div>
                {child.value && renderChild(child, isLocked, isUnitDownloaded)}
              </div>
            );
          }
          return null;
        });
    }
    return null;
  }

  return <div className={styles.subMenuContainer}>{renderNodesInMenu(hierarchicalContent)}</div>;
}

UnitLessonLevelMenu.propTypes = {
  initialHierarchicalContent: PropTypes.object,
  cptContent: PropTypes.object,
  currentNodesIds: PropTypes.array,
  productVariant: PropTypes.string,
  lockedNodes: PropTypes.array,
  closeMobileMenuModalAction: PropTypes.func,
  toggleModal: PropTypes.func,
  setCurrentContentData: PropTypes.func,
  hideActivities: PropTypes.bool,
  currentActivity: PropTypes.string,
  offlineUnits: PropTypes.array
};

const mapStateToProps = state => {
  const {
    cptContent,
    navigation: { currentNodesIds },
    productVariant,
    lockedNodes,
    hideActivities,
    currentActivity
  } = state.structuredContentPlayer;
  const { units: offlineUnits } = state.offlineContentPlayer;

  return {
    initialHierarchicalContent: cptContent.contents,
    currentNodesIds,
    productVariant,
    lockedNodes,
    hideActivities,
    currentActivity,
    offlineUnits
  };
};

const mapDispatchToProps = {
  setCurrentContentData: setCurrentContent,
  toggleModal: toggleContentsSubmenuModal,
  closeMobileMenuModalAction: closeMobileMenuModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(UnitLessonLevelMenu);
