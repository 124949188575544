import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';

import { userPreferencesRequest } from '../../redux/actions/orbUserPreferences';
import { initialiseInstance } from '../../redux/reducers/data/search.reducer';
import { getNotifications } from '../../redux/actions/notifications';
import ORBNotifications from '../ORBNotifications/ORBNotifications';

class ORBLayout extends Component {
  componentDidMount() {
    const {
      orgId,
      userId,
      userPreferences,
      subscriptionsLoaded,
      loadOrgSubscriptions,
      loadUserPreferences
    } = this.props;

    if (!subscriptionsLoaded) loadOrgSubscriptions(orgId);
    if (!userPreferences.success) loadUserPreferences(orgId, userId);
  }

  componentDidUpdate(prevProps) {
    const { orgId, userId, notifications, userPreferences, loadNotifications } = this.props;

    if (prevProps.userPreferences !== userPreferences && userPreferences.success && !notifications.success) {
      loadNotifications(userId, orgId);
    }
  }

  render() {
    const {
      notifications: { data },
      children
    } = this.props;

    return (
      <div>
        {data && data.length > 0 && <ORBNotifications notifications={data} />}
        {children}
      </div>
    );
  }
}

export default compose(
  connect(
    state => ({
      orgId: state.identity.currentOrganisationId || '',
      userId: state.identity.userId,
      userPreferences: state.orbUserPreferences,
      notifications: state.notifications,
      subscriptionsLoaded: Object.keys(state.subscriptions).length > 0
    }),
    dispatch => ({
      loadOrgSubscriptions: orgId =>
        dispatch(
          initialiseInstance('orgSubscriptions', false, {
            orgId,
            active: true,
            notStarted: true,
            expired: true,
            expiring: true
          })
        ),
      loadUserPreferences: (orgId, userId) => dispatch(userPreferencesRequest(orgId, userId)),
      loadNotifications: (userId, orgId) => dispatch(getNotifications(userId, orgId))
    })
  )
)(ORBLayout);

ORBLayout.propTypes = {
  notifications: PropTypes.shape({
    success: PropTypes.bool.isRequired,
    data: PropTypes.array
  }).isRequired,
  orgId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userPreferences: PropTypes.shape({
    success: PropTypes.bool.isRequired
  }).isRequired,
  subscriptionsLoaded: PropTypes.bool.isRequired,

  children: PropTypes.any,

  loadOrgSubscriptions: PropTypes.func.isRequired,
  loadUserPreferences: PropTypes.func.isRequired,
  loadNotifications: PropTypes.func.isRequired
};
