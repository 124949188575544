import * as t from '../../actionTypes';

const initialState = {
  units: {}
};

export default function downloadsReducer(state = initialState, action) {
  switch (action.type) {
    case t.START_UNIT_DOWNLOAD:
      return {
        ...state,
        units: {
          ...state.units,
          [action.payload.unitId]: action.payload
        }
      };
    case t.UPDATE_ACTIVITY_STATUS: {
      const { unitId, activityId, status, lastUpdateTime } = action.payload;

      const currentUnit = state.units[unitId];
      const currentActivity = currentUnit.activities[activityId];

      const updatedActivity = {
        ...currentActivity,
        status,
        lastUpdateTime
      };

      const updatedActivities = {
        ...currentUnit.activities,
        [activityId]: updatedActivity
      };

      const updatedUnit = {
        ...currentUnit,
        activities: updatedActivities
      };

      const updatedUnits = {
        ...state.units,
        [unitId]: updatedUnit
      };

      return {
        ...state,
        units: updatedUnits
      };
    }
    case t.UPDATE_UNIT_PROGRESS: {
      const { unitId, progress } = action.payload;

      const currentUnit = state.units[unitId];

      const updatedUnit = {
        ...currentUnit,
        progress,
        lastUpdateTime: Date.now()
      };

      const updatedUnits = {
        ...state.units,
        [unitId]: updatedUnit
      };

      return {
        ...state,
        units: updatedUnits
      };
    }
    case t.COMPLETE_UNIT_DOWNLOAD: {
      const { unitId } = action.payload;

      const currentUnit = state.units[unitId];

      const updatedUnit = {
        ...currentUnit,
        status: 'completed',
        lastUpdateTime: Date.now()
      };

      const updatedUnits = {
        ...state.units,
        [unitId]: updatedUnit
      };

      return {
        ...state,
        units: updatedUnits
      };
    }
    case t.FAIL_UNIT_DOWNLOAD: {
      const { unitId } = action.payload;

      const currentUnit = state.units[unitId];

      const updatedUnit = {
        ...currentUnit,
        status: 'failed',
        lastUpdateTime: Date.now()
      };

      const updatedUnits = {
        ...state.units,
        [unitId]: updatedUnit
      };

      return {
        ...state,
        units: updatedUnits
      };
    }
    case t.CONTINUE_UNIT_DOWNLOAD: {
      const { unitId } = action.payload;

      const currentUnit = state.units[unitId];

      const updatedUnit = {
        ...currentUnit,
        status: 'in progress',
        lastUpdateTime: Date.now()
      };

      const updatedUnits = {
        ...state.units,
        [unitId]: updatedUnit
      };

      return {
        ...state,
        units: updatedUnits
      };
    }
    default:
      return state;
  }
}
