import * as t from '../actionTypes';

export const getContentPreviewRequest = filters => ({
  type: t.GET_CONTENT_PREVIEW_REQUEST,
  payload: filters
});

export const getContentPreviewSuccess = data => ({
  type: t.GET_CONTENT_PREVIEW_SUCCESS,
  payload: data
});

export const getContentPreviewFailure = () => ({
  type: t.GET_CONTENT_PREVIEW_FAILURE
});

export const getEltGamesRequest = filters => ({
  type: t.GET_ELT_GAMES_REQUEST,
  payload: filters
});

export const getEReaderProductsRequest = filters => ({
  type: t.GET_EREADER_PRODUCTS_REQUEST,
  payload: filters
});

export const setGamesPreviewContentCode = contentCode => ({
  type: t.SET_ELT_GAMES_PREVIEW_CONTENT_CODE,
  payload: contentCode
});

export const resetGamesPreviewContentCode = () => ({
  type: t.RESET_ELT_GAMES_PREVIEW_CONTENT_CODE
});
