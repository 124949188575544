import { fork, put, race, take, takeEvery, cancel, select, delay, call } from 'redux-saga/effects';
import {
  COMPLETE_UNIT_DOWNLOAD,
  FAIL_UNIT_DOWNLOAD,
  START_UNIT_DOWNLOAD,
  UPDATE_ACTIVITY_STATUS,
  UPDATE_UNIT_PROGRESS,
  CONTINUE_UNIT_DOWNLOAD
} from '../../../../actionTypes';
import updateDownloadOfflineUnitStatus from '../ocp/updateDownloadOfflineUnitStatus';

function* monitorProductDownload(unitId) {
  const TIMEOUT_MS = 60000;

  try {
    while (true) {
      const state = yield select();
      const unitState = state.downloads.units[unitId];

      if (!unitState) {
        return;
      }

      const currentTime = Date.now();
      const activities = unitState.activities;

      // eslint-disable-next-line no-restricted-syntax
      for (const [activityId, activity] of Object.entries(activities)) {
        const isActivityDownloading = !activity.status || activity.status === 'downloading';
        const timeDiff = currentTime - activity.lastUpdateTime;
        if (isActivityDownloading && timeDiff > TIMEOUT_MS) {
          yield put({
            type: UPDATE_ACTIVITY_STATUS,
            payload: {
              unitId,
              activityId,
              status: 'failure'
            }
          });
        }
      }

      const totalActivities = Object.keys(activities).length;
      // TODO: create enum for success/failure status values
      const completedActivities = Object.values(activities).filter(a => a.status === 'success').length;
      const failedActivities = Object.values(activities).filter(a => a.status === 'failure').length;

      const progress = Math.round((completedActivities / totalActivities) * 100);

      yield put({
        type: UPDATE_UNIT_PROGRESS,
        payload: { unitId, progress }
      });

      if (completedActivities + failedActivities === totalActivities) {
        if (failedActivities === 0) {
          yield call(updateDownloadOfflineUnitStatus, { unitId, isDownloaded: true });
          yield put({
            type: COMPLETE_UNIT_DOWNLOAD,
            payload: { unitId }
          });
        } else {
          yield put({
            type: FAIL_UNIT_DOWNLOAD,
            payload: { unitId }
          });
        }
        return;
      }

      yield put({
        type: CONTINUE_UNIT_DOWNLOAD,
        payload: { unitId, progress }
      });

      yield delay(1000);
    }
  } catch (error) {
    console.error('Error in monitoring loop:', error);
  }
}

function* handleProductDownload(action) {
  const { unitId } = action.payload;

  const monitorTask = yield fork(monitorProductDownload, unitId);

  yield race({
    completed: take(act => act.type === COMPLETE_UNIT_DOWNLOAD && act.payload.unitId === unitId),
    failed: take(act => act.type === FAIL_UNIT_DOWNLOAD && act.payload.unitId === unitId)
  });

  yield cancel(monitorTask);
}

function* watchUnitDownloads() {
  yield takeEvery(START_UNIT_DOWNLOAD, handleProductDownload);
}

export default watchUnitDownloads;
