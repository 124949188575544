import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import compose from '../../utils/compose/compose.js';
import { GlyphIcon, EmblemSize, EmblemShape } from '../IconEmblem/IconEmblem';
import SVGIcon from '../SVGIcon/SVGIcon';
import { setCurrentContent, toggleContentsSubmenuModal } from '../../redux/actions/structuredContentPlayer';
import { getActivityLink } from '../../globals/urls';
import { isOcpMode } from '../../utils/platform';

import styles from './ContentPlayerNavigation.scss';
import Link from '../Link/Link';
import { onKeyDownHandler } from '../../services/cptHelperMethods';

function ContentPlayerNavigation({
  cptContent,
  modalBasePathLink,
  modalCloseLink,
  handleClosePanel,
  toggleModal,
  navigation,
  progressBarNavigation,
  offlineUnits
}) {
  const {
    currentActivities,
    currentActivityIndexInLesson,
    isFirstActivityInLesson,
    isLastActivityInLesson,
    previousActivity,
    nextActivity,
    nextLessonTitle,
    previousLessonTitle,
    grandparentLevelTitle,
    parentLevelTitle,
    disablePrevious,
    disableNext
  } = navigation;

  const { userId: offlineUserId } = useParams();

  const isNextActivityDownloaded =
    nextActivity && offlineUnits.some(unit => nextActivity.parentId.includes(unit.id) && unit.userId === offlineUserId);

  const isPreviousActivtiyDownloaded =
    previousActivity &&
    offlineUnits.some(unit => previousActivity.parentId.includes(unit.id) && unit.userId === offlineUserId);

  let isDisabledLeft = !previousActivity || disablePrevious;
  let isDisabledRight = !nextActivity || disableNext;

  if (isOcpMode()) {
    isDisabledLeft = isDisabledLeft || !isPreviousActivtiyDownloaded;
    isDisabledRight = isDisabledRight || !isNextActivityDownloaded;
  }

  let prevTitle = null;
  if (!isDisabledLeft) {
    prevTitle = isFirstActivityInLesson ? `Previous: ${previousLessonTitle}` : previousActivity.title;
  }

  let nextTitle = null;
  if (!isDisabledRight) {
    nextTitle = isLastActivityInLesson ? `Next: ${nextLessonTitle}` : nextActivity.title;
  }

  const prevLink = prevTitle ? getActivityLink(modalBasePathLink, previousActivity?.id) : null;
  const nextLink = nextTitle ? getActivityLink(modalBasePathLink, nextActivity?.id) : null;

  const mapCurrentActivities = () =>
    currentActivities.map((item, index) => (
      <div
        className={`${index <= currentActivityIndexInLesson ? styles.cpNavRowBarActive : styles.cpNavRowBarInactive}`}
        key={`${item.title}-${index}`}
      />
    ));

  const getDisplayName = () => {
    if (grandparentLevelTitle) {
      return `${grandparentLevelTitle} - ${parentLevelTitle}`;
    }
    return parentLevelTitle;
  };

  return (
    <div className={`${styles.cpNavRow} ${styles.cpNavRowMargins}`}>
      {isDisabledLeft ? (
        <div
          className={classnames(
            EmblemSize.MEDIUM,
            EmblemShape.SQUARE,
            styles.cpNextPrevButton,
            `${isDisabledLeft && styles.cpDisabled}`,
            styles[`progressBarNavigation${progressBarNavigation}`]
          )}
          aria-label={cptContent.previous_title}
        >
          <SVGIcon glyph={isFirstActivityInLesson ? GlyphIcon.ICON_DOUBLE_LEFT_THICK : GlyphIcon.ICON_LEFT_THICK_16} />
        </div>
      ) : (
        <Link
          to={prevLink}
          className={classnames(styles.cpNextPrevButton, styles[`progressBarNavigation${progressBarNavigation}`])}
        >
          <div
            className={classnames(
              EmblemSize.MEDIUM,
              EmblemShape.SQUARE,
              styles.cpNextPrevButton,
              `${isDisabledLeft && styles.cpDisabled}`
            )}
            role="button"
            tabIndex="-1"
            aria-label={cptContent.previous_title}
          >
            <SVGIcon
              glyph={isFirstActivityInLesson ? GlyphIcon.ICON_DOUBLE_LEFT_THICK : GlyphIcon.ICON_LEFT_THICK_16}
            />
          </div>
        </Link>
      )}
      <div className={styles.cpNavProgress}>
        <div className={`${styles.cpNavRow} ${styles.cpNavRowText}`}>
          <div className={styles.cpNextPrevTitle}>{prevTitle}</div>
          <div
            className={styles.cpNavProgressTitle}
            onClick={toggleModal}
            onKeyDown={onKeyDownHandler(toggleModal)}
            role="button"
            tabIndex="0"
          >
            <p className={styles.cpActivityTitle}>{getDisplayName()}</p>
          </div>
          <div className={`${styles.cpNextPrevTitle} ${styles.nextTitle}`}>{nextTitle}</div>
        </div>
        <div
          className={`${styles.cpNavRow} ${styles.cpNavRowText} ${styles.cpNavRowTextSecondLine} ${styles.cpNavRowBullet}`}
        >
          {mapCurrentActivities()}
        </div>
      </div>
      {isDisabledRight ? (
        <div
          className={classnames(
            EmblemSize.MEDIUM,
            EmblemShape.SQUARE,
            styles.cpNextPrevButton,
            `${isDisabledRight && styles.cpDisabled}`,
            styles[`progressBarNavigation${progressBarNavigation}`]
          )}
          aria-label={cptContent.next_title}
        >
          <SVGIcon glyph={isLastActivityInLesson ? GlyphIcon.ICON_DOUBLE_RIGHT_THICK : GlyphIcon.ICON_RIGHT_THICK_16} />
        </div>
      ) : (
        <Link
          to={nextLink}
          className={classnames(styles.cpNextPrevButton, styles[`progressBarNavigation${progressBarNavigation}`])}
        >
          <div
            className={classnames(
              EmblemSize.MEDIUM,
              EmblemShape.SQUARE,
              styles.cpNextPrevButton,
              `${isDisabledRight && styles.cpDisabled}`
            )}
            role="button"
            tabIndex="-1"
            aria-label={cptContent.next_title}
          >
            <SVGIcon
              glyph={isLastActivityInLesson ? GlyphIcon.ICON_DOUBLE_RIGHT_THICK : GlyphIcon.ICON_RIGHT_THICK_16}
            />
          </div>
        </Link>
      )}
      <div className={styles.cpCloseDiv}>
        {modalCloseLink ? (
          <Link to={modalCloseLink} onClick={handleClosePanel}>
            <div
              className={classnames(EmblemSize.MEDIUM, EmblemShape.SQUARE, styles.cpClose)}
              role="button"
              aria-label={cptContent.next_title}
              tabIndex="0"
            >
              <SVGIcon glyph={GlyphIcon.ICON_CLOSE} />
            </div>
          </Link>
        ) : (
          <Link onClick={handleClosePanel}>
            <div
              className={classnames(EmblemSize.MEDIUM, EmblemShape.SQUARE, styles.cpClose)}
              role="button"
              aria-label={cptContent.next_title}
            >
              <SVGIcon glyph={GlyphIcon.ICON_CLOSE} />
            </div>
          </Link>
        )}
      </div>
    </div>
  );
}

ContentPlayerNavigation.propTypes = {
  cptContent: PropTypes.object,
  modalBasePathLink: PropTypes.object,
  modalCloseLink: PropTypes.string,
  handleClosePanel: PropTypes.func,
  toggleModal: PropTypes.func,
  navigation: PropTypes.object,
  progressBarNavigation: PropTypes.string,
  offlineUnits: PropTypes.array
};

const mapStateToProps = ({
  structuredContentPlayer: { modalBasePathLink, progressBarNavigation },
  offlineContentPlayer: { units: offlineUnits }
}) => ({
  modalBasePathLink,
  progressBarNavigation,
  offlineUnits
});

const mapDispatchToProps = {
  setCurrentContentAction: setCurrentContent,
  toggleModal: toggleContentsSubmenuModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ContentPlayerNavigation);
